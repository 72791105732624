<template>
  <screen_width_cont>
    <div class="cont_300_basico ancho_acotado">
      <div class="header_top">
        <headerBack @back="backLocal" />
      </div>

      <!-- título principal -->
      <div class="MyDatRecPassTitle title">
        {{ t("app.myDataRecoverPass.tit") }}
      </div>

      <div class="MyDatRecPassText">
        {{ t("app.myDataRecoverPass.text") }}
      </div>

      <div class="MyDatRecPassPassActual">
        <inputBox
          typeInput="textP"
          :placeHolder="t('app.myDataRecoverPass.contrasena_actual')"
          requerido="*"
          :rut="false"
          :error="errorPassActual"
          @dispatchChangeData="updatePasswordActual"
          :mensajeError="[
            t('configuracion.data.textos_errores.campoObligatorio'),
            '',
          ]"
          @blur="passActualVisitada"
          :mensajeOk="{
            mostrar: false,
            texto: t('app.myDataRecoverPass.passOk'),
          }"
          :borrarDatos="borrarDatos"
          dataInicial=""
        />
      </div>
      

      <div class="MyDatRecPassInputs">
        <div class="MyDatRecPassInputs1">
          <!-- Pass -->
          <inputBox
            typeInput="textP"
            :placeHolder="t('app.myDataRecoverPass.nueva_contrasena')"
            requerido="*"
            :rut="false"
            :error="errorPass"
            @dispatchChangeData="updatePasswordUno"
            :mensajeError="[
              t('configuracion.data.textos_errores.campoObligatorio'),
              '',
            ]"
            @blur="passVisitada = true"
            :mensajeOk="{
              mostrar: mensajeOkPassMostrar,
              texto: t('app.myDataRecoverPass.passOk'),
            }"
            :borrarDatos="borrarDatos"
            dataInicial=""
          />
        </div>

        <div class="MyDatRecPassInputs2">
          <inputBox
            typeInput="textP"
            :placeHolder="t('app.myDataRecoverPass.confirm_nueva_contrasena')"
            requerido="*"
            :rut="false"
            :error="errorPassConfirm"
            @dispatchChangeData="updatePasswordDos"
            :mensajeError="[
              t('configuracion.data.textos_errores.campoObligatorio'),
              '',
            ]"
            :mensajeOk="{
              mostrar: mensajeOkConfirmPassMostrar,
              texto: t('app.myDataRecoverPass.passConfirmOk'),
            }"
            :class="!mensajeOkPassMostrar ? 'disabledComp' : ''"
            :borrarDatos="borrarDatos"
            dataInicial=""
          />
        </div>
      </div>

      <div class="MyDatRecPassErrorMessage">
        
      </div>

      <!-- <div class="MyDatRecPassFortalezas">
        <fortalezaPass
          :passVisitada="passVisitada"
          :errorPassCorta="errorPassCorta"
          :errorPassNumber="errorPassNumber"
          :errorPassSimbol="errorPassSimbol"
          :errorPassMayus="errorPassMayus"
          :errorPassMinus="errorPassMinus"
        />
      </div> -->

      <div class="MyDatRecPassActionBtn">
        <btnAction
          :textBtn="t('app.comunes.btns.guardar')"
          :disabled="!btnActive"
          @action="registerNewPass"
          colorBack="rojo"
          colorText="blco"
          :estadoBtn="estadoBtn"
          :msg_completar="t('app.comunes.btns.completar_formulario')"
        />
      </div>
    </div>
  </screen_width_cont>
</template>

<script>
import { menuController, alertController } from "@ionic/vue";
import headerBack from "../components/generales/headerBack.vue";
import { useI18n } from "vue-i18n";
import { mapActions, mapMutations, mapState } from "vuex";
// import fortalezaPass from "../components/remitente/fortalezaPass.vue";
import inputBox from "../components/generales/inputBox.vue";
import btnAction from "../components/generales/btnAction.vue";
import screen_width_cont from "../components/menuSp/screen_width_cont.vue";

export default {
  name: "recoverPassForm",
  setup() {
    const { t } = useI18n();
    return { t };
  },
  components: {
    headerBack,
    // fortalezaPass,
    inputBox,
    btnAction,
    screen_width_cont,
  },
  data() {
    return {
      errorPass: false,
      errorPassConfirm: false,
      errorPassActual: false,
      passwordUno: "",
      passwordDos: "",
      mensajeOkPassMostrar: false,
      mensajeOkConfirmPassMostrar: false,
      passVisitada: false,
      passwordDosVisitado: false,
      btnActive: true,
      mensajeErrorChangePass: "",
      borrarDatos: false,
      passwordActual: "",
      passActualVisitada: false,
      mensaje_error:"",
    };
  },
  computed: {
    ...mapState([
      "respForgotPass3",
      "menuSpDesde",
      "desde",
      "nav_stack",
      "widthScreen",
    ]),

    estadoBtn() {
      let aux = "inactive";
      if (
        this.passwordActual != "" &&
        this.passwordUno != "" &&
        this.passwordDos != ""
      ) {
        aux = "active";
      }
      return aux;
    },
  },
  methods: {
    ...mapMutations(["limpiarRemitente", "set_nav_stack", "setshowSpinner"]),
    ...mapActions(["changePassword_logued"]),

    backLocal() {
      // if(this.menuSpDesde.menuSp) menuController.open("firstMenu");
      // if(this.desde == "beneficiarioNuevo"){
      //   this.$router.push({ name: "nuevoDestinatarioForm", replace: true });
      // }else{
      //   this.$router.push({ name: this.menuSpDesde.desde, replace: true });
      // }

      if (this.nav_stack.menuSp) {
        menuController.open("firstMenu");
      }
      let aux = [...this.nav_stack.desde];
      let destino = aux.pop();
      this.set_nav_stack({ desde: aux, menuSp: false });
      this.$router.push({ name: destino, replace: true });
    },
    updatePasswordActual(data) {
      this.passwordActual = data;
      this.passActualVisitada = true;
    },
    //     passActualVisitada(){
    // console.log("////////////");
    //     },
    updatePasswordUno(data) {
      this.passVisitada = true;
      this.passwordUno = data;
      this.errorPass = false;
    },
    updatePasswordDos(data) {
      this.passwordDosVisitado = true;
      this.passwordDos = data;
    },
    registerNewPass() {
      // console.log("TODO ---------------------");
      let errores = false;
      this.errorPassActual = false;

      if (this.passwordActual.length == 0) {
        this.errorPassActual = true;
        errores = true;
      }

      if (!this.mensajeOkPassMostrar) {
        this.errorPass = true;
        this.passVisitada = true;
        errores = true;
      }
      if (!this.mensajeOkConfirmPassMostrar) {
        this.errorPassConfirm = true;
        errores = true;
      }

      if (errores) {
        // console.log("1");
        this.setshowSpinner(false);
        return;
      } else {
        // console.log("2");
        this.btnActive = false;

        this.changePassword_logued({
          clave_antigua: this.passwordActual.trim(),
          clave_nueva: this.passwordUno.trim(),
          clave_nueva2: this.passwordUno.trim()
        });
      }
    },
    async presentAlert() {
      let alert = await alertController.create({
        header: "Error",
        subHeader: this.mensajeErrorChangePass,
        buttons: ['Ok'],
        backdropDismiss: false,
      });
      await alert.present();
    },
  },
  watch: {
    respForgotPass3() {
      this.mensajeErrorChangePass = "";
      if (this.respForgotPass3.status) {
        this.setshowSpinner(false);
        if (this.respForgotPass3.resp) {

          if (this.nav_stack.menuSp) {
            menuController.open("firstMenu");
          }
          let aux = [...this.nav_stack.desde];
          let destino = aux.pop();
          this.set_nav_stack({ desde: aux, menuSp: false });
          this.$router.push({ name: destino, replace: true });
        } else {
          if(this.respForgotPass3.msj == "ERROR_CLI_018"){
            this.mensajeErrorChangePass = this.t(`configuracion.errores_srv.Cliente.${this.respForgotPass3.msj}`); 
          }else if(this.respForgotPass3.msj == "ERROR_CLI_019"){
            this.mensajeErrorChangePass = this.t(`configuracion.errores_srv.Cliente.${this.respForgotPass3.msj}`); 
          }
          
          this.presentAlert();
        }
        this.btnActive = true;
      }
    },
    passwordUno() {
      if (this.passwordUno.length > 5) {
        this.errorPassCorta = false;
      } else {
        this.errorPassCorta = true;
      }
      // if (this.passwordUno.length > 7) {
      //   this.errorPassCorta = false;
      // } else {
      //   this.errorPassCorta = true;
      // }

      // if (/[A-Z]/.test(this.passwordUno)) {
      //   this.errorPassMayus = false;
      // } else {
      //   this.errorPassMayus = true;
      // }

      // if (/[a-z]/.test(this.passwordUno)) {
      //   this.errorPassMinus = false;
      // } else {
      //   this.errorPassMinus = true;
      // }

      // if (/[0-9]/.test(this.passwordUno)) {
      //   this.errorPassNumber = false;
      // } else {
      //   this.errorPassNumber = true;
      // }

      // if (/[^a-zA-Z0-9&\\;<>]/.test(this.passwordUno)) {
      //   this.errorPassSimbol = false;
      // } else {
      //   this.errorPassSimbol = true;
      // }

      if (
        !this.errorPassCorta
        //  && !this.errorPassMayus &&
        // !this.errorPassMinus &&
        // !this.errorPassNumber &&
        // !this.errorPassSimbol
      ) {
        this.mensajeOkPassMostrar = true;
        if (this.passwordUno == this.passwordDos) {
          this.mensajeOkConfirmPassMostrar = true;
        } else { // if (this.passwordDosVisitado)
          this.mensajeOkConfirmPassMostrar = false;
        }
      } else {
        this.mensajeOkPassMostrar = false;
        this.mensajeOkConfirmPassMostrar = false;
      }

      if (this.passwordDosVisitado) {
        if (this.passwordUno != this.passwordDos) {
          this.errorPassConfirm = true;
        } else {
          this.errorPassConfirm = false;
        }
      }
    },
    passwordDos() {
      if (
        !this.errorPassCorta 
        // && !this.errorPassMayus &&
        // !this.errorPassMinus &&
        // !this.errorPassNumber &&
        // !this.errorPassSimbol
      ) {
        if (this.passwordUno == this.passwordDos) {
          this.mensajeOkConfirmPassMostrar = true;
        } else {
          this.mensajeOkConfirmPassMostrar = false;
        }
      } else {
        this.mensajeOkConfirmPassMostrar = false;
      }

      if (this.passwordUno != this.passwordDos) {
        this.errorPassConfirm = true;
      } else {
        this.errorPassConfirm = false;
      }
    },
    passActualVisitada() {
      this.errorPassActual = false;
    },
  },
  ionViewWillLeave() {
    this.passwordUno = "";
    this.passwordDos = "";
    this.borrarDatos = !this.borrarDatos;
  },
};
</script>

<style>
.MyDatRecPassTitle {
  margin-top: 1rem;

  text-align: center;
  color: var(--customA-801B1C);
}
.MyDatRecPassText {
  margin-top: 1.5rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.6875rem;
  line-height: 18px;
  display: flex;
  align-items: flex-end;
  text-align: justify;
  letter-spacing: 0.5px;
  color: var(--customG-787878);
}
.MyDatRecPassPassActual {
  margin-top: 2.5rem;
}

.MyDatRecPassInputs {
  margin-top: 2rem;
}
.MyDatRecPassInputs1 {
  margin-top: 1.1rem;
}
.MyDatRecPassInputs2 {
  margin-top: 0.4rem;
}
.MyDatRecPassErrorMessage {
  margin-top: 1rem;
  height: 1.53rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 0.6875rem;
  line-height: 13px;
  letter-spacing: 0.5px;
  color: var(--customA-801B1C);
}
.MyDatRecPassFortalezas {
  margin-top: 1rem;
  /* border: 1px solid green; */
}
.MyDatRecPassActionBtn {
  margin-top: 2.625rem;
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
}
</style>